import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ProjectCard from '../ProjectCard';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ProjectDialog(props) {
    const { open, project, token, user, setOpen, menuSelection } = props;

    const handleClose = () => {
        setOpen(false);
    };

    // If project is not provided, don't render the dialog
    if (!project) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="project-dialog-title"
            fullScreen  // Ensure full screen
            scroll="paper"  // Allow scrolling when content exceeds screen
        >

            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {project.name || 'Project Details'}
                    </div>
                    <div>
                        <IconButton
                            edge="end"
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    display: 'flex',
                    // justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflowY: 'auto',  // Enable vertical scrolling if needed
                    maxHeight: '100vh',  // Ensure it respects the screen height
                    padding: '16px',
                    boxSizing: 'border-box',
                }}
            >
                <div>
                    <ProjectCard
                        project={project}
                        user={user}
                        token={token}
                        menuSelection={menuSelection}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}